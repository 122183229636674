import React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Lang } from '../../../assets/lang/lang';
import { FormattedMessage } from 'react-intl';
import { useViewModel } from '../../../hooks/useViewModel';
import { NavBarLanguageSelectionViewModel } from './NavBarLanguageSelectionViewModel';
import NavBarLanguageSelectionViewStyles from './NavBarLanguageSelectionViewStyles';

const NavBarLanguageSelectionView = observer(() => {
	const classes = NavBarLanguageSelectionViewStyles();
	const viewModel: NavBarLanguageSelectionViewModel = useViewModel(NavBarLanguageSelectionViewModel);

	return (
		<div className={classes.container}>
			<IconButton color='inherit' aria-controls='navbar-menu' aria-haspopup='true' onClick={viewModel.menu.toggleMenu}>
				<span className={classes.language}>{viewModel.getLanguage().toUpperCase()}</span>
			</IconButton>
			<Menu
				id='navbar-menu'
				keepMounted
				anchorEl={viewModel.menu.anchor}
				open={viewModel.menu.open}
				onClose={viewModel.menu.closeMenu}
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
				<MenuItem
					selected={viewModel.isLanguageSelected(Lang.GERMAN)}
					onClick={() => {
						viewModel.menu.closeMenu();
						viewModel.changeLanguage(Lang.GERMAN);
					}}>
					<FormattedMessage id='navigation.language.de' />
				</MenuItem>
				<MenuItem
					selected={viewModel.isLanguageSelected(Lang.ENGLISH)}
					onClick={() => {
						viewModel.menu.closeMenu();
						viewModel.changeLanguage(Lang.ENGLISH);
					}}>
					<FormattedMessage id='navigation.language.en' />
				</MenuItem>
			</Menu>
		</div>
	);
});

export default NavBarLanguageSelectionView;
