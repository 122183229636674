import * as React from 'react';
import { ReactElement } from 'react';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import NavBarTitleViewStyles from './NavBarTitleViewStyles';
import { observer } from 'mobx-react';
import { useViewModel } from '../../../hooks/useViewModel';
import { NavBarTitleViewModel } from './NavBarTitleViewModel';

const NavBarTitleView = observer((): ReactElement => {
	const classes = NavBarTitleViewStyles();
	const viewModel: NavBarTitleViewModel = useViewModel(NavBarTitleViewModel);

	return (
		<>
			<Typography
				onClick={() => {
					viewModel.clearCourseName();
					viewModel.navigateHome();
				}}
				className={classes.headline}
				variant={'h1'}
				noWrap>
				<FormattedMessage id='application.title' />
			</Typography>
			{viewModel.getCourseName() && (
				<Typography variant={'h1'} noWrap>
					{viewModel.getCourseName()}
				</Typography>
			)}
		</>
	);
});

export default NavBarTitleView;
