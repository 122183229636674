import { ViewModel } from '../../../core/viewmodel/ViewModel';
import { StoreProvider } from '../../../stores/StoreProvider';
import * as H from 'history';
import { Pages } from '../../../navigation/Pages';
import { CourseService } from '../../../services/CourseService';
import { AuthStore } from '../../../stores/application/AuthStore';
import { SnackbarStore } from '../../../stores/application/SnackbarStore';
import { CourseStore } from '../../../stores/components/CourseStore';

export class NavBarTitleViewModel extends ViewModel {
	private readonly courseService: CourseService;
	private readonly snackbarStore: SnackbarStore;
	private readonly authStore: AuthStore;
	private readonly courseStore: CourseStore;

	constructor(storeProvider: StoreProvider, history: H.History) {
		super(storeProvider, history);
		this.courseService = storeProvider.courseService;
		this.authStore = storeProvider.authStore;
		this.snackbarStore = storeProvider.snackbarStore;
		this.courseStore = storeProvider.courseStore;
	}

	public navigateHome(): void {
		this.navigateTo(Pages.COURSES_OVERVIEW);
	}

	public getCourseName(): string {
		return this.courseStore.getCourse().name;
	}

	public clearCourseName(): void {
		this.courseStore.setCourseName('');
	}
}
