/* eslint-disable */

import * as React from 'react';
import { Redirect, Route } from 'react-router';
import { Pages } from './Pages';
import { ReactElement, useContext } from 'react';
import { StoreProvider } from '../stores/StoreProvider';
import { StoreContext } from '../stores/storeContext';
import { AuthStore } from '../stores/application/AuthStore';
import { observer } from 'mobx-react';

export const PrivateRoute = observer(({ component, ...rest }: any): ReactElement => {
	const storeProvider: StoreProvider = useContext(StoreContext);
	const authStore: AuthStore = storeProvider.authStore;

	const routeComponent = (props: any) =>
		authStore.hasAuthToken() ? (
			React.createElement(component, props)
		) : (
			<Redirect to={{ pathname: Pages.REQUEST_LOGIN }} />
		);
	return <Route {...rest} render={routeComponent} />;
});
