import { MaterialMenu } from '../../../models/material/MaterialMenu';
import { ViewModel } from '../../../core/viewmodel/ViewModel';
import { StoreProvider } from '../../../stores/StoreProvider';
import { Lang } from '../../../assets/lang/lang';
import * as H from 'history';
import { LanguageStore } from '../../../stores/application/LanguageStore';
import { LocStorageService } from '../../../services/LocStorageService';

export class NavBarLanguageSelectionViewModel extends ViewModel {
	public menu: MaterialMenu = new MaterialMenu();
	private readonly languageStore: LanguageStore;
	private readonly locStorageService: LocStorageService;

	constructor(storeProvider: StoreProvider, history: H.History) {
		super(storeProvider, history);
		this.languageStore = this.storeProvider.languageStore;
		this.locStorageService = this.storeProvider.locStorageService;
	}

	public getLanguage(): Lang {
		return this.languageStore.getLanguage();
	}

	public isLanguageSelected(language: Lang): boolean {
		return this.languageStore.isLanguageSelected(language);
	}

	public changeLanguage(language: Lang): void {
		this.locStorageService.changeLanguage(language);
		this.languageStore.changeLanguage(language);
	}
}
