import { createStyles, makeStyles, Theme } from '@material-ui/core';

const CoursesOverviewStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginTop: theme.spacing(4),
		},
		gridContainer: {
			marginTop: theme.spacing(3),
		},
	})
);

export default CoursesOverviewStyles;
