import { Lang } from '../../assets/lang/lang';
import { Store } from '../../core/store/Store';
import { LanguageModel } from '../../models/application/LanguageModel';

export class LanguageStore extends Store {
	private languageModel: LanguageModel = new LanguageModel();

	public getLanguage(): Lang {
		return this.languageModel.language;
	}

	public isLanguageSelected(language: Lang): boolean {
		return this.languageModel.isLanguageSelected(language);
	}

	public changeLanguage(language: Lang): void {
		return this.languageModel.changeLanguage(language);
	}
}
