import { createStyles, makeStyles } from '@material-ui/core';

const RouterConfigStyles = makeStyles(() =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
		},
		courseContainer: {
			paddingLeft: 0,
			display: 'flex',
			height: '100%',
		},
		minHeight: {
			minHeight: 'calc(100vh - 118px)',
		},
	})
);

export default RouterConfigStyles;
