export interface Column {
	id: string;
	title: string;
	format?: COLUMN_FORMAT;
}

export enum COLUMN_FORMAT {
	CHECKMARK = 'checkmark',
	SHORTEN = 'shorten',
}
