import React, { createContext } from 'react';
import { StoreProvider } from './StoreProvider';
import PropTypes from 'prop-types';

export const StoreContext = createContext<StoreProvider>({} as StoreProvider);
const state = new StoreProvider();

export const StoreContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
	return <StoreContext.Provider value={state}>{children}</StoreContext.Provider>;
};
StoreContextProvider.propTypes = {
	children: PropTypes.any,
};
