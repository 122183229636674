import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useViewModel } from '../../hooks/useViewModel';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import LoginDialogView from '../../pages/RequestAccess/LoginDialog/LoginDialogView';
import { LoginViewModel } from './LoginViewModel';
import LoginViewUseStyles from './LoginViewStyles';

const LoginView = observer(() => {
	const classes = LoginViewUseStyles();
	const viewModel: LoginViewModel = useViewModel(LoginViewModel);

	return (
		<Box display={'flex'} flexDirection={'column'} justifyContent={'center'} className={classes.right}>
			<Box className={classes.container}>
				<Button className={classes.login} variant='outlined' color='secondary' onClick={viewModel.openLogin}>
					<FormattedMessage id='requestAccess.login_button' />
				</Button>
				<Box className={classes.content} alignSelf={'flex-start'}>
					<Box className={classes.background}>
						<Box className={classes.highlight} />
					</Box>
					<Box className={classes.contentRight}>
						<img src='/img/mobile-preview.svg' alt='Image of Mobile Application' />
						<Typography variant={'subtitle1'}>
							<FormattedMessage id='requestAccess.klim_description' />
						</Typography>
					</Box>
				</Box>
			</Box>
			{viewModel.isLoginOpen() && <LoginDialogView />}
		</Box>
	);
});

export default LoginView;
