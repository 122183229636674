/* eslint-disable @typescript-eslint/no-var-requires */
import German from './de.json';
import English from './en.json';

const flatten = require('flat');

export const translations: {
	de: Record<string, string>;
	en: Record<string, string>;
} = {
	de: flatten(German),
	en: flatten(English),
};
