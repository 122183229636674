import { SnackbarStore } from './application/SnackbarStore';
import { AuthService } from '../services/AuthService';
import { LoginStore } from './components/LoginStore';
import { AuthStore } from './application/AuthStore';
import { RequestAccessService } from '../services/RequestAccessService';
import { CourseService } from '../services/CourseService';
import { EventStore } from './application/EventStore';
import { ProfileService } from '../services/ProfileService';
import { LocStorageService } from '../services/LocStorageService';
import { LanguageStore } from './application/LanguageStore';
import { StudentService } from '../services/StudentService';
import { ChangePasswordStore } from './components/ChangePasswordStore';
import { ResetPasswordService } from '../services/ResetPasswordService';
import { ModuleService } from '../services/ModuleService';
import { AddModuleDialogStore } from './components/AddModuleDialogStore';
import { EntryService } from '../services/EntryService';
import { EntriesReviewStore } from './components/EntriesReviewStore';
import { ChatService } from '../services/ChatService';
import { ChatStore } from './components/ChatStore';
import { CourseStore } from './components/CourseStore';
import { LegalTextService } from '../services/LegalTextService';

export class StoreProvider {
	public loginStore: LoginStore;
	public authStore: AuthStore;
	public eventStore: EventStore;
	public languageStore: LanguageStore;
	public snackbarStore: SnackbarStore;
	public changePasswordStore: ChangePasswordStore;
	public addModuleDialogStore: AddModuleDialogStore;
	public chatStore: ChatStore;
	public courseStore: CourseStore;
	public authService: AuthService;
	public requestAccessService: RequestAccessService;
	public courseService: CourseService;
	public profileService: ProfileService;
	public locStorageService: LocStorageService;
	public studentService: StudentService;
	public moduleService: ModuleService;
	public entryService: EntryService;
	public entriesReviewStore: EntriesReviewStore;
	public resetPasswordService: ResetPasswordService;
	public chatService: ChatService;
	public legalTextService: LegalTextService;

	constructor() {
		this.authService = new AuthService();
		this.requestAccessService = new RequestAccessService();
		this.courseService = new CourseService();
		this.profileService = new ProfileService();
		this.locStorageService = new LocStorageService();
		this.studentService = new StudentService();
		this.moduleService = new ModuleService();
		this.entryService = new EntryService();
		this.entriesReviewStore = new EntriesReviewStore();
		this.resetPasswordService = new ResetPasswordService();
		this.chatService = new ChatService();
		this.legalTextService = new LegalTextService();

		this.snackbarStore = new SnackbarStore();
		this.loginStore = new LoginStore();
		this.authStore = new AuthStore();
		this.eventStore = new EventStore();
		this.languageStore = new LanguageStore();
		this.changePasswordStore = new ChangePasswordStore();
		this.chatStore = new ChatStore();
		this.addModuleDialogStore = new AddModuleDialogStore();
		this.courseStore = new CourseStore();
	}
}
