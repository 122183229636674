import { action, makeObservable, observable } from 'mobx';
import { SnackbarKey } from 'notistack';

export interface Notification {
	key: SnackbarKey;
	message: string;
	options: {
		variant: string;
	};
}

export class MaterialSnackbar {
	@observable
	notifications: Notification[] = [];

	@observable
	closedNotifications: SnackbarKey[] = [];

	@action
	enqueueSnackbar(message: string, variant: string): void {
		this.notifications.push({
			key: new Date().getTime() + Math.random(),
			message: message,
			options: {
				variant: variant,
			},
		});
	}

	@action
	removeNotification(key: SnackbarKey): void {
		this.notifications = this.notifications.filter((notification) => notification.key !== key);
	}

	@action
	closeNotification(key: SnackbarKey): void {
		this.closedNotifications.push(key);
	}

	@action
	removeClosedNotification(key: SnackbarKey): void {
		this.closedNotifications = this.closedNotifications.filter((closedNotification) => closedNotification !== key);
	}

	constructor() {
		makeObservable(this);
	}
}
