import { MaterialSnackbar } from '../../models/material/MaterialSnackbar';
import { SnackbarKey } from 'notistack';

export class SnackbarStore {
	private snackbars: MaterialSnackbar[] = [];

	public registerSnackbar(snackbar: MaterialSnackbar): void {
		this.snackbars.push(snackbar);
	}

	public showSuccess(message: string): void {
		this.snackbars.forEach((snackbar) => {
			snackbar.enqueueSnackbar(message, 'success');
		});
	}

	public showWarning(message: string): void {
		this.snackbars.forEach((snackbar) => {
			snackbar.enqueueSnackbar(message, 'warning');
		});
	}

	public showError(message: string): void {
		this.snackbars.forEach((snackbar) => {
			snackbar.enqueueSnackbar(message, 'error');
		});
	}

	public showInfo(message: string): void {
		this.snackbars.forEach((snackbar) => {
			snackbar.enqueueSnackbar(message, 'info');
		});
	}

	public closeNotification(key: SnackbarKey): void {
		this.snackbars.forEach((snackbar) => {
			snackbar.closeNotification(key);
		});
	}
}
