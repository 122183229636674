import { observer } from 'mobx-react';
import { useViewModel } from '../../../hooks/useViewModel';
import { FormattedMessage } from 'react-intl';
import { Box, Button, IconButton, Link, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { ForgotPasswordFormViewModel } from './ForgotPasswordFormViewModel';
import ForgotPasswordFormStyles from './ForgotPasswordFormStyles';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';

const ForgotPasswordFormView = observer(() => {
	const classes = ForgotPasswordFormStyles();
	const viewModel: ForgotPasswordFormViewModel = useViewModel(ForgotPasswordFormViewModel);

	return (
		<>
			<Typography variant={'h2'} className={classes.resetTitle}>
				<FormattedMessage id='resetPassword.title' />
			</Typography>
			<Typography variant={'body2'}>
				<FormattedMessage id='resetPassword.message' />
			</Typography>
			<Typography className={classes.separator} variant={'h4'}>
				<FormattedMessage id='resetPassword.reset_password' />
			</Typography>
			{viewModel.isLoading() ? (
				<Box className={classes.circularProgress}>
					<CircularProgress />
				</Box>
			) : (
				<>
					<form
						onSubmit={async (e) => {
							e.preventDefault();
							await viewModel.requestReset();
						}}
						onError={(errors) => console.log(errors)}
						className={classes.formRoot}>
						<label id='email' className={classes.label}>
							<FormattedMessage id={'resetPassword.email'} />
						</label>
						<TextField
							required
							name='email'
							variant='outlined'
							fullWidth={true}
							size={'small'}
							value={viewModel.getEMail()}
							onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => viewModel.setEMail(e)}
						/>

						<Box display={'flex'} justifyContent={'flex-end'}>
							<Button
								disabled={viewModel.disableRequestButton()}
								variant='contained'
								color='secondary'
								type='submit'
								className={classes.submitButton}>
								<FormattedMessage id='resetPassword.reset_password' />
							</Button>
						</Box>
					</form>
					<div className={classes.cancelContainer} onClick={() => viewModel.cancel()}>
						<IconButton className={classes.cancelButton}>
							<CancelIcon />
						</IconButton>
						<Link className={classes.cancelText}>
							<FormattedMessage id={'resetPassword.cancel'} />
						</Link>
					</div>
				</>
			)}
		</>
	);
});

export default ForgotPasswordFormView;
