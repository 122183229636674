import React from 'react';
import { Box } from '@material-ui/core';
import RequestAccessViewUseStyles from './RequestAccessViewStyles';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import RequestAccessFormView from './RequestAccessForm/RequestAccessFormView';
import LoginView from '../../components/Login/LoginView';
import { RequestAccessViewModel } from './RequestAccessViewModel';
import { useViewModel } from '../../hooks/useViewModel';
import UnauthenticatedFooterView from '../../components/Footer/UnauthenticatedFooter/UnauthenticatedFooterView';

const RequestAccessView = observer(() => {
	const viewModel: RequestAccessViewModel = useViewModel(RequestAccessViewModel);
	const classes = RequestAccessViewUseStyles();

	return (
		<>
			<Box className={classes.root} display={'flex'} flexDirection={'row'}>
				<Box
					display={'flex'}
					flexDirection={'column'}
					justifyContent={'center'}
					alignItems={'flex-end'}
					className={classes.left}>
					<Box className={classes.container}>
						<Box className={classes.contentLeft}>
							<Box className={classes.form}>
								<RequestAccessFormView />
							</Box>
						</Box>
						<UnauthenticatedFooterView />
					</Box>
				</Box>
				<LoginView />
			</Box>
			{viewModel.didResetPassword() && (
				<Box className={classes.info} display={'flex'} alignItems={'center'} justifyContent={'center'}>
					<FormattedMessage id={'resetPassword.reset_success'} />
				</Box>
			)}
		</>
	);
});

export default RequestAccessView;
