import { ViewModel } from '../viewmodel/ViewModel';
import { StoreProvider } from '../../stores/StoreProvider';
import { Lang } from '../../assets/lang/lang';
import { translations } from '../../assets/lang/translations';
import { SnackbarStore } from '../../stores/application/SnackbarStore';
import { SnackbarKey } from 'notistack';
import * as H from 'history';
import { AuthStore } from '../../stores/application/AuthStore';
import { EventStore } from '../../stores/application/EventStore';
import { EventTypes } from '../../models/application/EventModel';
import { LocStorageService } from '../../services/LocStorageService';
import { LanguageStore } from '../../stores/application/LanguageStore';

export class AppViewModel extends ViewModel {
	private readonly snackbarStore: SnackbarStore;
	private readonly authStore: AuthStore;
	private readonly eventStore: EventStore;
	private readonly languageStore: LanguageStore;
	private readonly locStorageService: LocStorageService;

	constructor(storeProvider: StoreProvider, history: H.History) {
		super(storeProvider, history);
		this.snackbarStore = this.storeProvider.snackbarStore;
		this.authStore = this.storeProvider.authStore;
		this.eventStore = this.storeProvider.eventStore;
		this.languageStore = this.storeProvider.languageStore;
		this.locStorageService = this.storeProvider.locStorageService;
		this.loadUser();
		this.setLanguage();
	}

	private loadUser(): void {
		const user = this.locStorageService.loadUserFromStorage();
		this.authStore.saveUser(user);
	}

	private setLanguage(): void {
		if (!!this.locStorageService.getLanguage()) {
			const locale = this.locStorageService.getLanguage() as Lang;
			this.languageStore.changeLanguage(locale);
		} else if (navigator.language.includes('de')) {
			this.languageStore.changeLanguage(Lang.GERMAN);
		} else {
			this.languageStore.changeLanguage(Lang.ENGLISH);
		}
	}

	public getLanguage(): Lang {
		return this.languageStore.getLanguage();
	}

	public getTranslation(): Record<string, string> {
		return translations[this.getLanguage()];
	}

	public closeNotification(key: SnackbarKey): void {
		this.snackbarStore.closeNotification(key);
	}

	public isLogin = (): boolean => {
		return this.authStore.hasAuthToken();
	};

	public subscribeToUserChanges(): EventTypes[] {
		return this.eventStore.subscribeToEvents(
			[EventTypes.USER_LOGIN, EventTypes.USER_LOGOUT, EventTypes.USER_UPDATE],
			this
		);
	}

	public updateLocStorage(): void {
		const user = this.authStore.getUser();
		this.locStorageService.saveUserToStorage(user);
	}
}
