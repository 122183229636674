/* eslint-disable */

import { useContext, useRef } from 'react';
import { StoreContext } from '../stores/storeContext';
import { useHistory } from 'react-router-dom';

export function useViewModel(ViewModel: any, ...args: any[]): any {
	const vmRef = useRef(null);

	const storeProvider = useContext(StoreContext);
	const history = useHistory();

	if (!vmRef.current) {
		vmRef.current = new ViewModel(storeProvider, history, ...args);
	}

	return vmRef.current;
}
